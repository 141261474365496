export const waitForCondition = ({
  condition,
  callback,
  delay = 250,
  timeout = 5000,
}: {
  condition: () => boolean
  callback: (isConditionMet: boolean) => void
  delay?: number
  timeout?: number
}) => {
  const maxCount: number = Math.ceil(timeout / delay)
  let count: number = maxCount
  let interval: NodeJS.Timeout

  new Promise<void>((resolve) => {
    interval = setInterval(() => {
      const isConditionMet = condition()
      if (isConditionMet || count <= 0) {
        // Resolve if tagcommander exists or if timeout is reached
        callback(!!isConditionMet)
        resolve()
      }
      count--
    }, delay)
  }).then(() => clearInterval(interval))
}