import type { IExternalScript } from '../contexts/ExternalScripts'
import config from '../config'
import { OUTBRAIN_SCRIPT_ID } from '../constants/scripts'

const outbrain: IExternalScript = {
  id: OUTBRAIN_SCRIPT_ID,
  src: `//widgets.outbrain.com/outbrain.js?i=${config.outbrain.id}`,
  async: true,
  consent: [],
  waitFirstConsent: true,
}

export default outbrain
