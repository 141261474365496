import type { ICactConsentResult, ICMPStatus, IConsentStatusGlobal } from '../types/cmp'

import { useCallback, useEffect, useState } from 'react'
import {
  getConsent,
  hasCorrectConsent as hasCorrectConsentHelper,
  isFirstConsent,
} from '../helpers/cmp'
import { UNSET_CONSENT } from '../constants/cmp'
import config from '../config'

export interface IUseCMP {
  status: ICMPStatus
  hasCorrectConsent: boolean
  consentString: string
  isFirstConsentGiven: boolean
  loading: boolean
  checkConsents: (requiredConsents?: string[], requiredVendors?: string[]) => boolean
}

export function useCMP(requiredConsents: string[] = [], requiredVendors: string[] = []): IUseCMP {
  const [consents, setConsents] = useState<ICactConsentResult['consent'] | null>(null)
  const [status, setStatus] = useState<IConsentStatusGlobal>(UNSET_CONSENT)
  const [consentString, setConsentString] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const isFirstConsentGiven = isFirstConsent() || status !== UNSET_CONSENT

  const hasCorrectConsent = hasCorrectConsentHelper(consents, requiredConsents, requiredVendors)

  const checkConsents = useCallback(
    (testConsents = [], testVendors = []) =>
      hasCorrectConsentHelper(consents, testConsents, testVendors),
    [consents],
  )

  function handleConsents({ consent }: ICactConsentResult) {
    setStatus(consent.status)
    setConsents(consent)

    window?.__tcfapi('addEventListener', 2, function (pingReturn, success) {
      if (
        success &&
        (pingReturn.eventStatus === 'tcloaded' ||
          pingReturn.eventStatus === 'useractioncomplete') &&
        consent.status !== UNSET_CONSENT
      ) {
        setConsentString(pingReturn.tcString)
      }

      if (!success) {
        setConsentString(config.cmp.defaultConsentString)
      }
    })

    setLoading(false)
  }

  useEffect(() => {
    getConsent(handleConsents)
    window.cact('consent.onUpdate', handleConsents)
  }, [])

  useEffect(() => {
    let currentTime = 0
    const checkInterval = 1000
    if (loading) {
      const cmpTimeout = setInterval(() => {
        currentTime += checkInterval

        if (currentTime >= config.cmp.timeout) {
          setStatus(UNSET_CONSENT)
          setConsents({
            status: UNSET_CONSENT,
            categories: null,
            vendors: null,
          })
          setConsentString(config.cmp.defaultConsentString)
          clearTimeout(cmpTimeout)
        }
      }, checkInterval)

      return () => {
        clearTimeout(cmpTimeout)
      }
    }
  }, [loading])

  return {
    hasCorrectConsent,
    consentString,
    isFirstConsentGiven,
    status,
    loading,
    checkConsents,
  }
}
