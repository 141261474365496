import type { CreativeWork } from 'schema-dts'
import type { IDataMain, IPage } from '../../types/page'

import { videoObject } from './videoObject'
import { picturesToImageObject } from '../seoFormat'

export default function associatedMediaObject(
  page: IPage['page'],
  keywords: string[],
): CreativeWork['associatedMedia'] {
  const image = page?.image?.url || ''
  const mainSection = page.data.find((elem) => elem.key === 'main') as IDataMain
  const bodyHeaderSection = mainSection.data.find((elem) => elem.key === 'body-header')?.data ?? []
  const bodySection = mainSection.data.find((elem) => elem.key === 'body')?.data ?? []
  const blocks = [...bodyHeaderSection, ...bodySection]

  const associatedMedia = []

  blocks.forEach((block) => {
    switch (block.key) {
      case 'article-header-video': {
        associatedMedia.push(
          videoObject({ video: block.data.video, keywords, image, publishedAt: page.createdAt }),
        )
        break
      }
      case 'multi-live': {
        block.data.elementList.forEach((elem) => {
          if ('type' in elem && elem.type === 'video') {
            associatedMedia.push(
              videoObject({ video: elem.video, keywords, image, publishedAt: page.createdAt }),
            )
          }
          if ('type' in elem && elem.type === 'image') {
            associatedMedia.push(picturesToImageObject(elem.pictures, true))
          }
        })
        break
      }
      case 'article-video': {
        associatedMedia.push(
          videoObject({ video: block.data.video, keywords, image, publishedAt: page.createdAt }),
        )
        break
      }
      case 'article-image': {
        associatedMedia.push(picturesToImageObject(block.data.pictures, true))
        break
      }
    }
  })

  return associatedMedia.filter(Boolean)
}
