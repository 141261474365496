import type { User } from '../types/user'
import type { GigyaResponse } from '../types/gigya'
import { useContext } from 'react'
import { GigyaContext } from '../contexts/GigyaAuth'

export interface IUseUser {
  isLoggedIn: boolean
  userProfile: User
  jwt: string
  loading: boolean
  updateUser: (user: Partial<User>) => Promise<GigyaResponse>
  refreshToken: () => void
  disconnectUser: () => void
}

export function useUser(): IUseUser {
  const { user, jwt, updateUser, disconnectUser, hasError, isGigyaReady, refreshToken } =
    useContext(GigyaContext)

  return {
    isLoggedIn: !!user && !hasError,
    jwt: !hasError ? jwt : null,
    userProfile: !hasError ? user : null,
    loading: !isGigyaReady,
    refreshToken,
    updateUser,
    disconnectUser,
  }
}
