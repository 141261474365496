import type { ImageObject } from 'schema-dts'
import type { IPicture } from '../../types/pictures'
import type { IImageObjectData } from '../structuredData/imageObject'
import imageObject from '../structuredData/imageObject'

export function formatKeywords(text = '') {
  return text
    .split(',')
    .map((str) => str.trim())
    .filter((str) => str?.length)
}

export const seoMultiliveHeadline = (text: string): string => {
  const valueBeetwenBoldRegex = /<(strong|b)([^><]+)?>([^><]+)<\/(strong|b)>/
  const value = (text.match(valueBeetwenBoldRegex) && text.match(valueBeetwenBoldRegex)[3]) || text
  const headline = value.replace(/<[^>]*>/g, '').trim()
  return headline.length > 110 ? headline.slice(0, 106) + '...' : headline
}

export const seoMultiliveArticleBody = (text: string): string => {
  const valueBeetwenBoldRegex = /<(strong|b)([^><]+)?>([^><]+)<\/(strong|b)>/
  const value = text.replace(valueBeetwenBoldRegex, '').replace(/\n/g, ' ') || text
  const body = value.replace(/<[^>]*>/g, '').trim()
  return body
}

export const formatDate = (date: string | Date | number): string => {
  const updateDate = new Date(date)
  const hour = updateDate.getHours()
  const minute = updateDate.getMinutes()
  return `${updateDate.toLocaleDateString('fr-FR')} ${hour > 9 ? hour : `0${hour}`}:${
    minute > 9 ? minute : `0${minute}`
  }`
}

export function picturesToImageObject(
  pictures: IPicture,
  isAssociatedMedia?: boolean,
): ImageObject {
  if (!pictures?.elementList?.length) return null
  const authorizedType = ['image/jpeg', 'image/jpg', 'image/png']
  let maxImageObjData: IImageObjectData | null = null
  let maxWidth = 0

  for (const pictureElement of pictures.elementList) {
    const { url, width, height, type } = pictureElement

    const parsedWidth = typeof width === 'number' ? width : parseInt(width, 10)
    if (parsedWidth > maxWidth && authorizedType.includes(type)) {
      maxWidth = parsedWidth
      maxImageObjData = {
        url,
        width,
        height,
        format: type,
      }
    }
  }
  return imageObject({ data: maxImageObjData, isAssociatedMedia })
}
