import { isServer } from './client'

export const getAllCookies = () => {
  if (!global?.document?.cookie) return {}
  return document.cookie.split('; ').reduce((cookies, cookie) => {
    const [key, value] = cookie.split('=')
    return { ...cookies, [key]: JSON.parse(value) }
  }, {})
}

export function getCookie(name: string): string {
  if (isServer()) return null

  const regex = new RegExp(
    '(?:(?:^|.*;)\\s*' +
      // eslint-disable-next-line
      encodeURIComponent(name).replace(/[\-\.\+\*]/g, '\\$&') +
      '\\s*\\=\\s*([^;]*).*$)|^.*$',
  )

  return decodeURIComponent(document.cookie.replace(regex, '$1')) || null
}

export const setCookie = ({
  name,
  value,
  days = 1,
  expireAt,
}: {
  name: string
  value: string
  days?: number
  expireAt?: Date
}) => {
  let d = new Date()
  if (expireAt) {
    d = expireAt
  } else {
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
  }
  const expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export const setupOriginUrlCookie = (path) => {
  setCookie({ name: 'originUrl', value: path, days: 0.01 })
}

export function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=0'
}
