import type { IPageData } from '../../../types/page'

import { useEffect } from 'react'
import { useScript } from '../../../hook/useScript'
import { NONLI_SCRIPT_ID } from '../../../constants/scripts'
import { isServer } from '../../../helpers/client'

interface IScriptProbeNonli {
  page: IPageData
}

export function ScriptProbeNonli({ page }: IScriptProbeNonli): JSX.Element {
  const { activeScript } = useScript({ id: NONLI_SCRIPT_ID, loadOnMount: false })

  useEffect(() => {
    if (!isServer() && page?.layout === 'content') {
      window._nli = [['brand', 'BNLI-1426']]
      activeScript()
    }
  }, [])

  return null
}
