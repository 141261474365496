import type { PropsWithChildren } from 'react'
import { Context, useEffect } from 'react'

import { createContext, useState } from 'react'
import { isIOS } from '../helpers/client'

export interface IPlayerFullScreenProps {
  isFullScreenActive: boolean
}

export type IPlayerFullScreenContext = Context<IPlayerFullScreenProps>

export const PlayerFullScreenContext: IPlayerFullScreenContext = createContext({
  isFullScreenActive: false,
})

// This context is only necessary for iOS devices
// the "Fullscreen" mode developed by the player is very special...
export function PlayerFullScreenContextProvider({ children }: PropsWithChildren): JSX.Element {
  const isIOSDevice = isIOS()
  const [isFullScreenActive, setIsFullScreenActive] = useState(false)

  const handleFullScreenChange = (isFullScreen: boolean) => {
    setIsFullScreenActive(isFullScreen)
  }

  useEffect(() => {
    if (!isIOSDevice) return
    const observer = new MutationObserver(function () {
      handleFullScreenChange(document.body.style.overflow === 'hidden')
    })

    const target = document.body

    observer.observe(target, {
      attributes: true,
      attributeFilter: ['style'],
    })
  }, [])

  if (!isIOSDevice) return <>{children}</>

  return (
    <PlayerFullScreenContext.Provider value={{ isFullScreenActive }}>
      {children}
    </PlayerFullScreenContext.Provider>
  )
}
