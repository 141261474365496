import type { SMO } from '../../../types/page'
import Head from 'next/head'
import config from '../../../config'
import { PAGE_TYPE_AUTHOR } from '../../../constants/page'

export interface ISEOOpenGraph {
  title?: string
  description?: string
  channel?: string
  canonicalUrl?: string
  type?: string
  digitalProgram?: {
    title?: string
  }
  facebookPage?: string
  createdAt?: string
  updatedAt?: string
  seo?: {
    title?: string
    description?: string
  }
  image?: {
    url?: string
  }
  smo: SMO
  category: {
    id: string
    name: string
  }
  enableOGType?: boolean
}

export function SEOOpenGraph({
  canonicalUrl,
  category,
  channel,
  createdAt,
  description,
  facebookPage,
  image,
  seo,
  smo,
  title,
  type,
  updatedAt,
  enableOGType,
}: ISEOOpenGraph): JSX.Element {
  const seoTitle: string = smo?.title || seo?.title || title
  const seoDescription: string = smo?.description || seo?.description || description
  const seoImage: string =
    smo?.image ||
    smo?.facebook?.image ||
    image?.url ||
    `${config.domain}/images/tf1info-share-default.jpg`

  return (
    <Head>
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content={channel || 'TF1 INFO'} />

      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {seoDescription && <meta property="og:description" content={seoDescription} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:image" content={seoImage} />
      {(enableOGType || type === PAGE_TYPE_AUTHOR) && <meta property="og:type" content="website" />}
      {type === 'content' && (
        <>
          <meta property="og:type" content="article" />
          <meta property="article:section_id" content={category?.id || ''} />
          <meta property="article:section" content={category?.name || ''} />
          <meta property="article:published_time" content={createdAt} />
          <meta property="article:modified_time" content={updatedAt} />
          {facebookPage && <meta property="article:publisher" content={facebookPage} />}
        </>
      )}
    </Head>
  )
}
