import { useScript } from '../../../hook/useScript'

export interface IScriptProbe {
  id: string
}

export function ScriptProbe({ id }: IScriptProbe): JSX.Element {
  useScript({ id })
  return null
}
