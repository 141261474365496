export const GIGYA_RESPONSE_OK = 'OK' as const

export const GIGYA_SCREEN_LOGIN = 'gigya-login-screen' as const
export const GIGYA_SCREEN_PROFILE_UPDATE = 'gigya-update-profile-screen' as const
export const GIGYA_SCREEN_CHANGE_PASSWORD = 'gigya-change-password-screen' as const
export const GIGYA_SCREEN_REGISTRATION = 'gigya-register-screen' as const
export const GIGYA_SCREEN_REGISTRATION_COMPLETION = 'gigya-complete-registration-screen' as const
export const GIGYA_SCREEN_VERIFICATION_MAIL = 'gigya-verification-sent-screen' as const
export const GIGYA_SCREEN_REGISTRATION_OPTIONS = 'gigya-registration-options-screen' as const

export const GIGYA_REGISTRATION_ORIGIN_SERVICE_IOS = 'appIOS' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_ANDROID = 'appAndroid' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_WEB = 'web' as const

export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_POUR_VOUS = 'PourVous' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_JTPERSO = 'JTPerso' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_MENU = 'Menu' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_DIRECT_ACCESS = 'AccesDirect' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_NEWSLETTER = 'Newsletter' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BOOKMARK = 'Bookmark' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_CAROUSEL_NEWSLETTER =
  'Carrousel_newsletter' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_VOTE = 'vote' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BEOP = 'beop' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_LIVE = 'Live-tf1' as const
export const GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_HEADER = 'Header' as const

export const ZIP_FIELD_NAME = 'profile.zip' as const
export const BIRTH_DAY_FIELD_NAME = 'profile.birthDay' as const
export const BIRTH_MONTH_FIELD_NAME = 'profile.birthMonth' as const
export const BIRTH_YEAR_FIELD_NAME = 'profile.birthYear' as const
export const BIRTH_DATE_FIELDS = [
  BIRTH_DAY_FIELD_NAME,
  BIRTH_MONTH_FIELD_NAME,
  BIRTH_YEAR_FIELD_NAME,
]

export const SUBSCRIBED_NEWSLETTER_PARAM = 'subscribedNewsletterId'
export const SUBSCRIBED_BOOKMARK_PARAM = 'subscribedBookmarkId'

export const GIGYA_LOADING_STATUS_LOADING = 'loading' as const
export const GIGYA_LOADING_STATUS_LOADED = 'loaded' as const
export const GIGYA_LOADING_STATUS_ERROR = 'error' as const

export const GIGYA_BUTTON_DATA_ID = 'data-gigya-button-id' as const
export const GIGYA_REMOVE_ACCOUNT_MODAL_OPEN = 'remove-account-modal-open' as const
export const GIGYA_DOWNLOAD_PROFILE_ID = 'download-profile-account' as const
