import config from '../../../config'
import iconsPath from '../../../public/images/icons/paths.json'
import { LciLogo } from '../Icon/LciLogo'
interface ISVGIcon extends React.SVGProps<SVGSVGElement> {
  name: string
  size?: string | number
  width?: string
  height?: string
  className?: string
  primaryColor?: string
  secondaryColor?: string
  orientation?: 'up' | 'right' | 'down' | 'left'
}

function getGlyph(glyph: string) {
  try {
    // eslint-disable-next-line
    const symbol = require(`../../../public/images/icons${iconsPath[glyph]}`)
    return [symbol, null]
  } catch (err) {
    // eslint-disable-next-line
    console.error(err)
    return [null, err]
  }
}

const transformPerOrientation = {
  up: 'rotate(270deg)',
  down: 'rotate(90deg)',
  left: 'rotate(180deg)',
}

export function SVGIcon({
  className,
  name,
  size = '24px',
  width,
  height,
  orientation,
  primaryColor,
  secondaryColor,
  ...rest
}: ISVGIcon) {
  const [symbol, error] = getGlyph(name)
  if (!symbol || error) {
    // eslint-disable-next-line no-undef
    return config.environment !== 'production' ? (
      <span>Error : No SVGIcon &quot;{name}&quot;</span>
    ) : null
  }

  const computeSize = typeof size === 'number' ? `${size}px` : size
  if (name == 'lci') return <LciLogo size={40} />
  return (
    <>
      <svg
        viewBox={symbol.viewBox}
        className={className || ''}
        aria-hidden
        focusable="false"
        {...rest}
      >
        <use xlinkHref={symbol.url} />
      </svg>

      <style jsx>{`
        svg {
          ${primaryColor ? `--svg-color-primary: ${primaryColor}` : ''};
          ${secondaryColor ? `--svg-color-secondary: ${secondaryColor}` : ''};
          width: ${width || computeSize};
          height: ${height || computeSize};

          ${transformPerOrientation[orientation] ? `transform: ${transformPerOrientation[orientation]}` : ''};
        }
      `}</style>
    </>
  )
}
