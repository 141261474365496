const ONE_MIN_IN_MS = 1000 * 60
const ONE_H_IN_MS = ONE_MIN_IN_MS * 60
const ONE_DAY_IN_MS = 86400000
const ONE_MONTH_IN_MS = 2629800000
const ONE_YEAR_IN_MS = 31557600000

const dateTimeFormat = new Intl.DateTimeFormat('fr', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})

const setDigits = (time: number, x?: number) => {
  if (!x) return `${time}`

  return `${'0'.repeat(x - 1)}${time}`.slice(-x)
}

export const isValidDate = function (date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime())
}

export const timeFormat = (
  date: Date,
  { separator = 'h', digitsHours = 0, digitsMinutes = 2 } = {
    separator: 'h',
    digitsHours: 0,
    digitsMinutes: 2,
  },
): string => {
  const hh = date.getHours()
  const mm = date.getUTCMinutes()
  const time = [setDigits(hh, digitsHours || 0), setDigits(mm, digitsMinutes || 0)].join(
    separator || 'h',
  )

  return time
}

export function dateFormat(date: Date): string {
  return (
    date?.toLocaleDateString('fr-FR', {
      month: 'long',
      day: 'numeric',
    }) || ''
  )
}

export function format({
  publishedAt,
  updatedAt,
  formatType,
  prefix,
}: {
  publishedAt: Date
  updatedAt: Date
  formatType?: 'TIME' | 'DATE' | 'DATE_TIME'
  prefix?: string
}): {
  dateSEO: string
  date: string
} {
  const compare = compareDate(publishedAt, updatedAt)

  const result = formatDate({ date: publishedAt, isRelative: false, formatType, prefix })

  if (compare === -1) {
    const updatedFormatted = formatDate({
      date: updatedAt,
      isRelative: true,
      prefix: ', mis à jour ',
      formatType,
    })
    result.date += updatedFormatted.date
    result.dateSEO += updatedFormatted.dateSEO
  }

  return result
}

export const simpleDate = (date: Date): string => dateTimeFormat.format(date)

//YYYY-MM-DD
export const formatSimpleDate = (date: string): string => {
  if (!date) return ''
  const d = new Date(date)
  return d?.toISOString()?.split('T')?.[0] ?? ''
}

export function formatDate({
  date,
  isRelative = false,
  prefix = 'Publié ',
  formatType,
}: {
  date: Date
  isRelative?: boolean
  prefix?: string
  formatType?: 'TIME' | 'DATE' | 'DATE_TIME'
}): {
  dateSEO: string
  date: string
} {
  const now = new Date(Date.now())
  const startingToday = startOfDay(now)
  const dateSEO = `${prefix ? 'l' : 'L'}e ${dateTimeFormat.format(date)} à ${timeFormat(date)}`

  let dateFormatted = null

  if (formatType === 'DATE') {
    const formatedDateSEO = `${prefix ? 'l' : 'L'}e ${dateTimeFormat.format(date)}`

    if (startingToday.getTime() < date.getTime()) {
      // same day
      dateFormatted = `${prefix ? 'a' : 'A'}ujourd’hui`
    }

    // yesterday
    else if (startingToday.getTime() - ONE_H_IN_MS * 24 < date.getTime()) {
      dateFormatted = `${prefix ? 'h' : 'H'}ier`
    }

    return {
      dateSEO: prefix ? prefix + formatedDateSEO : formatedDateSEO,
      date: prefix ? prefix + (dateFormatted || formatedDateSEO) : dateFormatted || formatedDateSEO,
    }
  }

  if (startingToday.getTime() < date.getTime()) {
    // same day
    if (isRelative) {
      const nowTime = now.getTime()
      const dateTime = date.getTime()
      dateFormatted =
        nowTime - ONE_H_IN_MS < dateTime
          ? `il y a ${Math.trunc((nowTime - dateTime) / ONE_MIN_IN_MS)} min`
          : `à ${timeFormat(date)}`
    } else {
      dateFormatted = `aujourd'hui à ${timeFormat(date)}`
    }

    // yesterday
  } else if (startingToday.getTime() - ONE_H_IN_MS * 24 < date.getTime()) {
    dateFormatted = `hier à ${timeFormat(date)}`
  }

  return {
    dateSEO: prefix ? prefix + dateSEO : dateSEO,
    date: prefix ? prefix + (dateFormatted || dateSEO) : dateFormatted || dateSEO,
  }
}

export function startOfDay(date: Date): Date {
  const start = new Date(date.getTime())
  start.setHours(0, 0, 0, 0)
  return start
}

/**
 * if (result === 0) The two dates are equal
 * if (return === 1) publishedAt is the most recent
 * if (return === -1) updatedAt is the most recent
 */
function compareDate(publishedAt: Date, updatedAt: Date) {
  const diff = publishedAt.getTime() - updatedAt.getTime()

  if (Math.abs(diff) < ONE_MIN_IN_MS * 5) {
    return 0
  }

  return diff < 0 ? -1 : 1
}

// Manage french plural
const pluralize = (count: number, word: string): string => {
  return `${count} ${count > 1 ? word + 's' : word}`
}

export function fromDateFormatter(date: Date): string {
  const now = startOfDay(new Date(Date.now())).getTime()
  const roundedDate = startOfDay(date).getTime()
  const comparedDate = now - roundedDate
  if (now === roundedDate) return "aujourd'hui"
  if (comparedDate === ONE_DAY_IN_MS) return 'hier'
  if (comparedDate > ONE_YEAR_IN_MS) {
    const count = Math.round(comparedDate / ONE_YEAR_IN_MS)
    return `il y a ${pluralize(count, 'an')}`
  }

  if (comparedDate > ONE_MONTH_IN_MS) {
    const count = Math.round(comparedDate / ONE_MONTH_IN_MS)
    return `il y a ${count} mois`
  }

  const count = Math.round(comparedDate / ONE_DAY_IN_MS)
  return `il y a ${pluralize(count, 'jour')}`
}

const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0')

export const toISOStringWithParisTimezone = (entry: string | number | Date) => {
  const date = new Date(entry)
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)

  const parisHour = utcDate
    .toLocaleString('fr-FR', { timeZone: 'Europe/Paris', hour: '2-digit', minute: '2-digit' })
    .split(':')?.[0]

  const gmt = Number(parisHour) - Number(utcDate.toISOString().split('T')[1].split(':')[0])

  const sign = gmt < 0 ? '-' : '+'

  return utcDate.toISOString().slice(0, 19) + sign + pad(isNaN(gmt) ? 1 : gmt) + ':00'
}
