import type { IPageData } from '../../../types/page'
import Head from 'next/head'
import config from '../../../config'
import claimReviewObject from '../../../helpers/structuredData/claimReview'

interface ISEOFactChecking {
  page: IPageData
}

export function SEOFactChecking({ page }: ISEOFactChecking): JSX.Element {
  const { factChecking } = page?.seo || {}

  if (!factChecking) return null

  const claimReview = claimReviewObject(page)

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(claimReview, null, config.environment === 'production' ? null : 4),
        }}
      />
    </Head>
  )
}
