import type { Event, ImageObject } from 'schema-dts'
import postalAddressObject from './postalAddress'

export interface IEventData {
  name: string
  description: string
  title: string
  image: ImageObject
  startDate: string
  endDate: string
}

const presets: Record<string, IEventData> = {}

export default function eventObject(obj: IEventData, preset?: keyof typeof presets): Event | null {
  const data: IEventData = obj || presets?.[preset]

  if (!data) {
    return null
  }

  return {
    '@type': 'Event',
    name: data.name,
    description: data.description,
    alternateName: data.title,
    startDate: data.startDate,
    endDate: data.endDate,
    image: data.image,
    location: {
      '@type': 'Place',
      address: postalAddressObject(null, 'tf1'),
    },
  }
}
