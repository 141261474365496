import type { IExternalScript } from '../contexts/ExternalScripts'
import { TEADS_SCRIPT_ID } from '../constants/scripts'
import { PUBLICITE_CIBLE } from '../constants/cmp'
import config from '../config'

const gam: IExternalScript = {
  id: TEADS_SCRIPT_ID,
  src: 'https://a.teads.tv/analytics/tag.js',
  async: true,
  onMount: (): void => {
    window.teads_analytics = window.teads_analytics || {}
    window.teads_analytics.analytics_tag_id = config.teads.id
    window.teads_analytics.share =
      window.teads_analytics.share ||
      function (...rest) {
        window.teads_analytics.shared_data = window.teads_analytics.shared_data || []
        window.teads_analytics.shared_data.push(rest)
      }
  },
  consent: [PUBLICITE_CIBLE],
}

export default gam
