import type { IBlock } from '../../components/templates/Block'
import type { IDataMain, IPage } from '../../types/page'
import { BODY_KEY, MAIN_KEY } from '../../constants/block'

export function getBlockFromMainBody(
  page: IPage['page'],
  key: IBlock['key'],
): Extract<IBlock, { key: typeof key }> | null {
  const mainBlock = page?.data?.find?.((block) => block.key === MAIN_KEY) as IDataMain
  const body = mainBlock?.data?.find((block) => block.key === BODY_KEY)
  return body?.data?.find((block) => block.key === key)
}
