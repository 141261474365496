import type { IExternalScriptContext } from '../contexts/ExternalScripts'
import { useContext, useEffect } from 'react'
import { ScriptContext } from '../contexts/ExternalScripts'
import { useCMP } from './useCMP'

export interface IuseScript {
  isScriptLoaded: boolean
  isScriptActive: boolean
  hasScriptError: boolean
  removeScript: () => void
  activeScript: () => void
}

export function useScript({
  id,
  loadOnMount = true,
}: {
  id: string
  loadOnMount?: boolean
}): IuseScript {
  const { scripts, activeScript, removeScript }: IExternalScriptContext = useContext(ScriptContext)
  const { hasCorrectConsent, isFirstConsentGiven } = useCMP(scripts[id]?.consent || [])

  function activeCurrentScript() {
    const isFirstConsentAskedAndGiven =
      !scripts[id]?.waitFirstConsent || (scripts[id]?.waitFirstConsent && isFirstConsentGiven)
    const isNecessaryConsentGiven = !scripts[id].consent.length || hasCorrectConsent

    if (isFirstConsentAskedAndGiven && isNecessaryConsentGiven) {
      activeScript(id)
    }
  }

  function removeCurrentScript() {
    return removeScript(id)
  }

  useEffect(() => {
    if (loadOnMount && scripts?.[id] && !scripts[id]?.active) {
      activeCurrentScript()
    }
  }, [hasCorrectConsent, isFirstConsentGiven])

  if (!scripts?.[id]) {
    return {
      isScriptLoaded: false,
      isScriptActive: false,
      hasScriptError: true,
      removeScript: () => null,
      activeScript: () => null,
    }
  }

  return {
    isScriptLoaded: scripts[id].loaded,
    isScriptActive: scripts[id].active,
    hasScriptError: scripts[id].error,
    activeScript: activeCurrentScript,
    removeScript: removeCurrentScript,
  }
}
