import type { IExternalScript } from '../contexts/ExternalScripts'
import { FACILITI_SCRIPT_ID } from '../constants/scripts'
import config from '../config'

const faciliti: IExternalScript = {
  id: FACILITI_SCRIPT_ID,
  src: 'https://cdn.facil-iti.app/tags/faciliti-tag.min.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => setLoaded(true),
  consent: [],
  waitFirstConsent: true,
  attributes: {
    'data-application-identifier': config.faciliti.id,
  },
}

export default faciliti
