import slugify from 'slugify'

import type { User } from '../types/user'
import { DISPLAY_PAGE } from '../constants/tms'
import { getDeviceOS, getDeviceName } from './client'
import { getCookie } from './cookies'
import { stringToSHA256 } from './crypto'
import { AAM_COOKIE_KEY, TC_UNIQUE_COOKIE_KEY, ATIDVISTOR_COOKIE_KEY } from '../constants/cookies'
import { getCategoriesLabelsFromIds } from './categories'

export async function generateTMSClientData(
  user: User,
  userConnected: boolean,
  extraData?: { [key: string]: string },
): Promise<{ [key: string]: string }> {
  const optinThematics = await getCategoriesLabelsFromIds(
    typeof user?.data?.Tf1Info?.favoriteCategories === 'string'
      ? user?.data?.Tf1Info?.favoriteCategories || ''
      : user?.data?.Tf1Info?.favoriteCategories?.join(';') || '',
  )

  return {
    id: DISPLAY_PAGE,
    screen_url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    device_deviceName: getDeviceName(),
    device_deviceOs: getDeviceOS(),
    device_userAgent: window.navigator.userAgent,
    account_context_creation: user?.data?.Tf1Info?.registrationOrigin?.serviceType || '',
    user_birthDate:
      user?.profile?.birthYear && user.profile.birthMonth && user.profile.birthDay
        ? `${user.profile.birthDay}/${user.profile.birthMonth}/${user.profile.birthYear}`
        : '',
    user_dmpUid: getCookie(AAM_COOKIE_KEY) || '',
    user_email_1: user?.data?.hashEmail || '',
    user_gender: user?.profile?.gender || '',
    user_gigyaId: (user?.UID && (await stringToSHA256(user?.UID))) || '',
    user_name: user?.profile?.firstName || '',
    user_optinNews: Object.keys(user?.preferences ?? {})
      .filter((newsletterName) => newsletterName.includes('optin'))
      .map((newsletterName) => slugify(newsletterName, { lower: true }))
      .join(';'),
    user_optinThematic: optinThematics,
    user_signupDate: user?.data?.Tf1Info?.createdAt
      ? new Date(user.data.Tf1Info.createdAt).toLocaleDateString('fr-FR', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        })
      : null,
    user_tagCoUid: getCookie(TC_UNIQUE_COOKIE_KEY) || '',
    user_userLogged: userConnected.toString(),
    user_xitiUid: getCookie(ATIDVISTOR_COOKIE_KEY) || '',
    user_zipCode: user?.profile?.zip || '',
    ...(extraData || {}),
  }
}
