import { useEffect } from 'react'
import { useScript } from '../../../hook/useScript'
import { useCMP } from '../../../hook/useCMP'
import { GIGYA_SCRIPT_ID } from '../../../constants/scripts'
import {
  PUBLICITE_CIBLE,
  TCF_CONSENT,
  YOUTUBE_REQUIRED_CONSENT_VENDORS,
} from '../../../constants/cmp'
import { setupGamCookies } from '../../../api/client/ssr'

export const GAMCookieProbe = (): JSX.Element => {
  const { isScriptLoaded: isGigyaScriptLoaded } = useScript({ id: GIGYA_SCRIPT_ID })
  const { hasCorrectConsent: isConsentGam, loading: isLoadingConsent } = useCMP(
    [...TCF_CONSENT, PUBLICITE_CIBLE],
    YOUTUBE_REQUIRED_CONSENT_VENDORS,
  )

  useEffect(() => {
    if (isGigyaScriptLoaded && !isLoadingConsent) {
      if (isConsentGam) {
        window.gigya.accounts?.getAccountInfo({
          callback: (user) => {
            setupGamCookies(user)
          },
        })
      } else {
        setupGamCookies(null, true)
      }
    }
  }, [isGigyaScriptLoaded, isConsentGam, isLoadingConsent])

  return null
}
