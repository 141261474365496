import { theme } from '../styles/theme'
import { isServer } from '../helpers/client'
import { useEffect, useState } from 'react'
import { debounce } from '../helpers/debounce'

export type IViewport =
  | typeof theme.breakpoints.xl
  | typeof theme.breakpoints.lg
  | typeof theme.breakpoints.md
  | typeof theme.breakpoints.sm
  | 0

export function getViewport() {
  if (isServer()) {
    return 0
  }
  const { innerWidth } = window

  if (innerWidth >= theme.breakpoints.xl) {
    return theme.breakpoints.xl
  }
  if (innerWidth >= theme.breakpoints.lg) {
    return theme.breakpoints.lg
  }
  if (innerWidth >= theme.breakpoints.md) {
    return theme.breakpoints.md
  }

  return theme.breakpoints.sm
}

export function useViewport(): {
  viewport?: IViewport
  isMobile?: boolean
  isTablet?: boolean
  isDesktop?: boolean
} {
  const [viewport, setViewport] = useState<IViewport>(undefined)

  const onWindowResize = () => {
    setViewport(getViewport())
  }

  const onWindowResizeDebounced = debounce(onWindowResize, 500)

  useEffect(() => {
    onWindowResizeDebounced()
    window.addEventListener('resize', onWindowResizeDebounced, { passive: true })
    return function cleanup() {
      window.removeEventListener('resize', onWindowResizeDebounced)
    }
  }, [])

  const isMobile = viewport ? viewport === theme.breakpoints.sm : undefined
  const isTablet = viewport ? viewport === theme.breakpoints.md : undefined
  const isDesktop = viewport
    ? viewport === theme.breakpoints.lg || viewport === theme.breakpoints.xl
    : undefined

  return { viewport, isMobile, isTablet, isDesktop }
}
