import { useEffect } from 'react'
import { BATCH_SCRIPT_ID } from '../../../constants/scripts'
import { useScript } from '../../../hook/useScript'
import { useUser } from '../../../hook/useUser'
import config from '../../../config'
import { IPageData } from '../../../types/page'

interface ScriptProbeBatch {
  wordings: IPageData['config']['notifications']
}

const initBatchSDK = (wordings: ScriptProbeBatch['wordings'] = {}) => {
  const isDevelopment = config.environment === 'development'
  /* Initiate Batch SDK opt-in UI configuration (native prompt) */
  let batchSDKUIConfig: any = {
    native: {},
  }

  /* Use a specific configuration for Firefox and Safari browsers (custom prompt) */
  if (
    navigator.userAgent.indexOf('Firefox') !== -1 ||
    (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1)
  ) {
    batchSDKUIConfig = {
      alert: {
        text: wordings.text || "Ne manquez rien de l'actualité en vous abonnant à nos alertes.", //Ajouter le texte de l'alerte ici
        positiveSubBtnLabel: wordings.positiveSubBtnLabel || "S'abonner",
        negativeBtnLabel: wordings.negativeBtnLabel || 'Non merci',
        positiveBtnStyle: {
          backgroundColor: wordings.backgroundColor || '#000A68', // Couleur du bouton
          hoverBackgroundColor: wordings.hoverBackgroundColor || '#48C7FF', // Couleur du bouton de survol
          textColor: wordings.textColor || '#F0F0FF', // Couleur du bouton du texte
        },
      },
    }
  }

  /* Finalize the Batch SDK setup */
  window.batchSDK('setup', {
    apiKey: config.batch.apiKey,
    subdomain: 'tf1info',
    authKey: config.batch.authKey,
    dev: isDevelopment,
    vapidPublicKey: config.batch.vapidPublicKey,
    ui: batchSDKUIConfig,
    safari: {
      [config.baseUrl]: config.beop.safariUrl,
    },
  })
}

export function ScriptProbeBatch({ wordings }): JSX.Element {
  const { isScriptLoaded: isBatchScriptLoaded } = useScript({ id: BATCH_SCRIPT_ID })
  const { loading, userProfile } = useUser()

  useEffect(() => {
    if (isBatchScriptLoaded) {
      initBatchSDK(wordings)
    }
  }, [isBatchScriptLoaded])

  useEffect(() => {
    if (!loading && isBatchScriptLoaded) {
      window.batchSDK(function (api) {
        api.setCustomUserID(userProfile?.UID ?? null)
      })
    }
  }, [loading, isBatchScriptLoaded])

  return null
}
