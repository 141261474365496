import { useEffect } from 'react'
import { OWNPAGE_SCRIPT_ID } from '../../../constants/scripts'
import { useScript } from '../../../hook/useScript'
import { useUser } from '../../../hook/useUser'
import config from '../../../config'

export function ScriptProbeOwnPage(): JSX.Element {
  const { userProfile, loading } = useUser()
  const { isScriptLoaded } = useScript({ id: OWNPAGE_SCRIPT_ID })

  useEffect(() => {
    if (!loading && isScriptLoaded) {
      window?.Ownpage?.trackPage(config.ownpage.id, userProfile?.data?.hashEmail || '')
    }
  }, [loading, isScriptLoaded])

  return null
}
