import { theme } from '../styles'

export const websiteDevices = [
  'website',
  'websiteDesktop',
  'websiteTablet',
  'websiteMobile',
] as const

export const appDevices = [
  'app',
  'appIos',
  'appAndroid',
  'appIosTablet',
  'appAndroidTable',
] as const

export const devicesFromViewport = {
  [theme.breakpoints.sm]: 'websiteMobile',
  [theme.breakpoints.md]: 'websiteMobile',
  [theme.breakpoints.lg]: 'websiteTablet',
  [theme.breakpoints.xl]: 'websiteDesktop',
} as const

export const devices = [...websiteDevices, ...appDevices] as const
