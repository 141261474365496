import type { CreativeWork } from 'schema-dts'

export interface ICreativeWorkData {
  url: string
  text: string
}

export interface ICreativeWork extends ICreativeWorkData {
  '@type': 'CreativeWork'
}

const presets: Record<string, ICreativeWorkData> = {}

export default function creativeWorkObject(
  obj: ICreativeWorkData,
  preset?: keyof typeof presets,
): CreativeWork | null {
  const data: ICreativeWorkData = obj || presets?.[preset]

  if (!data) {
    return null
  }

  return {
    '@type': 'CreativeWork',
    url: data.url,
    text: data.text,
  }
}
