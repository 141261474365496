import Head from 'next/head'
import config from '../../../config'
import newsMediaOrganizationObject from '../../../helpers/structuredData/newsMediaOrganization'

const structuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'TF1 Info',
  url: 'https://www.tf1info.fr',
  countryOfOrigin: {
    '@type': 'Country',
    name: 'France',
  },
  inLanguage: 'fr',
  isAccessibleForFree: true,
  isFamilyFriendly: true,
  publisher: newsMediaOrganizationObject(),
}

export function SEOWebsite(): JSX.Element {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            structuredData,
            null,
            config.environment === 'production' ? null : 4,
          ),
        }}
      />
    </Head>
  )
}
