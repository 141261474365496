import { FORYOU_URL } from '../../constants/home'

export function getDeviceName(userAgent?: string): string {
  const agent = isServer() ? userAgent || '' : userAgent || navigator?.userAgent || ''
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(agent)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      agent,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

export function getDeviceOS(userAgent?: string): string {
  const agent = isServer() ? userAgent || '' : userAgent || navigator?.userAgent || ''
  if (agent.indexOf('like Mac') !== -1) {
    return 'ios'
  }
  if (agent.indexOf('Mac') !== -1) {
    return 'macintosh'
  }
  if (agent.indexOf('Android') !== -1) {
    return 'android'
  }
  if (agent.indexOf('Linux') !== -1) {
    return 'linux'
  }
  if (agent.indexOf('Win') !== -1) {
    return 'windows'
  }
  return 'unknown'
}

export function isBot(userAgent?: string): boolean {
  const agent = isServer() ? userAgent || '' : userAgent || navigator?.userAgent || ''
  return (
    agent.indexOf('Googlebot') !== -1 ||
    agent.indexOf('bingbot') !== -1 ||
    agent.indexOf('MicrosoftPreview') !== -1 ||
    agent.indexOf('Amazonbot') !== -1 ||
    agent.indexOf('DuckDuckBot') !== -1 ||
    agent.indexOf('Applebot') !== -1
  )
}

export function isChrome(userAgent?: string): boolean {
  const agent = isServer() ? userAgent || '' : userAgent || navigator?.userAgent || ''
  return agent.indexOf('Chrome') !== -1 || agent.indexOf('CriOS') !== -1
}

export const isServer = (): boolean => typeof window === 'undefined'

export const isPathHomeForYou = (slug: string) =>
  slug?.split('?')?.[0].split('/')?.[slug.startsWith('/') ? 1 : 0] === FORYOU_URL

export const isPathLive = (slug: string) =>
  slug?.split('?')?.[0].split('/')?.[slug.startsWith('/') ? 1 : 0] === 'direct'

export const isPathEmission = (slug: string) =>
  slug?.split('?')?.[0].split('/')?.[slug.startsWith('/') ? 1 : 0] === 'emission'

export const isPathHome = (slug: string) => slug === '/'

/* eslint-disable */
// Player helpers from player repo
const iDevices = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
  'MacIntel',
]
export const isTouch = () =>
  typeof window !== 'undefined' &&
  (Boolean(
    'ontouchstart' in window ||
      // @ts-ignore
      (window.DocumentTouch &&
        typeof document !== 'undefined' &&
        // @ts-ignore
        document instanceof window.DocumentTouch),
  ) ||
    Boolean(
      typeof window.navigator !== 'undefined' &&
        //@ts-ignore
        (window.navigator.maxTouchPoints || window.navigator.msMaxTouchPoints),
    ))

export const isIOS = () =>
  typeof window !== 'undefined' && isTouch() && iDevices.includes(window.navigator.platform)

/* eslint-enable */
