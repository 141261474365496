import { useEffect } from 'react'
import { GOOGLE_AD_MANAGER_SCRIPT_ID, TEADS_SCRIPT_ID } from '../../../constants/scripts'
import { useScript } from '../../../hook/useScript'

export function ScriptProbeTeads(): JSX.Element {
  const { isScriptLoaded: isGoogleScriptLoaded } = useScript({ id: GOOGLE_AD_MANAGER_SCRIPT_ID })
  const { activeScript } = useScript({ id: TEADS_SCRIPT_ID, loadOnMount: false })

  useEffect(() => {
    if (isGoogleScriptLoaded) {
      activeScript()
    }
  }, [isGoogleScriptLoaded])

  return null
}
