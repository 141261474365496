import type { IExternalScript } from '../contexts/ExternalScripts'
import { OPTA_SCRIPT_ID } from '../constants/scripts'

const opta: IExternalScript = {
  id: OPTA_SCRIPT_ID,
  src: 'https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    setLoaded(true)
  },
  consent: [],
}

export default opta
