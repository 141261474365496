import { IExternalScript } from '../contexts/ExternalScripts'
import batch from './batch'
import beop from './beop'
import facebook from './facebook'
import faciliti from './faciliti'
import gam from './gam'
import gigya from './gigya'
import hubvisor from './hubvisor'
import instagram from './instagram'
import nonli from './nonli'
import opta from './opta'
import outbrain from './outbrain'
import ownpage from './ownpage'
import pooolAccess from './pooolAccess'
import pooolEngage from './pooolEngage'
import pooolAudit from './pooolAudit'
import pubstack from './pubstack'
import pym from './pym'
import tagcommander from './tagcommander'
import teads from './teads'
import twitter from './twitter'

const scripts: Array<IExternalScript> = [
  batch,
  beop,
  facebook,
  faciliti,
  gam,
  gigya,
  hubvisor,
  instagram,
  nonli,
  opta,
  outbrain,
  ownpage,
  pooolAccess,
  pooolEngage,
  pooolAudit,
  pubstack,
  pym,
  tagcommander,
  teads,
  twitter,
]

export default scripts
