import { ReactElement, ReactNode, Context, MutableRefObject, useMemo } from 'react'
import { createContext, useState, useContext, useEffect } from 'react'
import Router from 'next/router'
import { END, PAUSE, PLAY } from '../constants/playerEvents'

export const PlayerManagerContext: Context<{
  handleEvent: (id: string, event: string) => void
  currentVideoPlaying: string
  currentPlayerFloating: string
  setCurrentPlayerFloating: (playerId: string) => void
}> = createContext({
  handleEvent: () => null,
  currentVideoPlaying: '',
  currentPlayerFloating: '',
  setCurrentPlayerFloating: () => null,
})

export interface IPlayerManagerContextWrapper {
  children: ReactElement | ReactNode
}

export const usePlayerManager = (
  playerId: string,
  playerRef: MutableRefObject<{
    pause: (userInteraction?: boolean) => void
    on: (event: string, callback: () => void) => void
    off: (event: string, callback: () => void) => void
  }>,
  isPlayerInitialised: boolean,
  canFloat: boolean,
): { isFloating: boolean; closePlayer: () => void } => {
  const { handleEvent, currentVideoPlaying, currentPlayerFloating, setCurrentPlayerFloating } =
    useContext(PlayerManagerContext)
  const [isPlaying, setisPlaying] = useState(false)

  const isFloating = useMemo(
    () => playerId === currentPlayerFloating && canFloat,
    [playerId, currentPlayerFloating, canFloat],
  )

  const pausePlayer = async () => {
    // Waiting to know if the player is paused to prevent lauching pause event unecessarily
    if (isPlaying) {
      playerRef.current?.pause(true)
    }
  }

  const closePlayer = async () => {
    // No player should float
    setCurrentPlayerFloating('')
    // Pause the player as it is no longer floating
    pausePlayer()
  }

  useEffect(() => {
    // Current video playing change and it's not this one -> pause
    if (playerId !== currentVideoPlaying && playerRef.current) {
      pausePlayer()
    }
  }, [currentVideoPlaying])

  const handlePlayEvent = () => {
    setisPlaying(true)
    handleEvent(playerId, PLAY)
  }
  const handlePauseEvent = () => {
    setisPlaying(false)
    handleEvent(playerId, PAUSE)
  }
  const handleEndEvent = () => {
    setisPlaying(false)
    handleEvent(playerId, END)
  }

  useEffect(() => {
    if (isPlayerInitialised) {
      playerRef.current?.on?.(PLAY, handlePlayEvent)
      playerRef.current?.on?.(PAUSE, handlePauseEvent)
      playerRef.current?.on?.(END, handleEndEvent)
    }
    return () => {
      playerRef.current?.off?.(PLAY, handlePlayEvent)
      playerRef.current?.off?.(PAUSE, handlePauseEvent)
      playerRef.current?.off?.(END, handleEndEvent)
    }
  }, [isPlayerInitialised])

  return { isFloating, closePlayer }
}

export const PlayerManagerContextWrapper = ({
  children,
}: IPlayerManagerContextWrapper): JSX.Element => {
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState<string>('')
  const [currentPlayerFloating, setCurrentPlayerFloating] = useState<string>(null)

  const resetManager = () => {
    setCurrentVideoPlaying('')
    setCurrentPlayerFloating(null)
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', resetManager)
    return () => {
      Router.events.off('routeChangeStart', resetManager)
    }
  }, [])

  const handleEvent = (id: string, event: string) => {
    setCurrentVideoPlaying((previousId) => {
      // if a new video is playing, set this video playing
      if (event === PLAY) {
        return id
      }
      // if a video is paused and it's the current video playing, no video is playing
      if (event === PAUSE && id === previousId) {
        return ''
      }
      // else nothing change
      return previousId
    })

    setCurrentPlayerFloating((previousId) => {
      switch (event) {
        // if a new video is playing, select it as the floating player
        case PLAY:
          return id
        // if the current video is end, no video is floating
        case END:
          return ''
        // else nothing change
        default:
          return previousId
      }
    })
  }

  return (
    <PlayerManagerContext.Provider
      value={{
        handleEvent,
        currentVideoPlaying,
        currentPlayerFloating,
        setCurrentPlayerFloating,
      }}
    >
      {children}
    </PlayerManagerContext.Provider>
  )
}
