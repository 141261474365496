import type { IExternalScript } from '../contexts/ExternalScripts'
import { HUBVISOR_SCRIPT_ID } from '../constants/scripts'
import config from '../config'

const gam: IExternalScript = {
  id: HUBVISOR_SCRIPT_ID,
  src: `https://cdn.hubvisor.io/wrapper/${config.hubvisor.id}/tf1.js`,
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    window.Hubvisor = window.Hubvisor || { cmd: [] }
    window.Hubvisor.cmd.push(() => {
      setLoaded(true)
    })
  },
  consent: [],
}

export default gam
