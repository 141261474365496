import getConfig from 'next/config'
import deepmerge from 'deepmerge'
import { IConfig } from './index.d'

const nextConfig = getConfig()

const serverRuntimeConfig = nextConfig?.serverRuntimeConfig || {}
export const publicRuntimeConfig = nextConfig?.publicRuntimeConfig || {}

export default deepmerge(serverRuntimeConfig, publicRuntimeConfig) as IConfig
