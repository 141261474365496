import { VIDEO_LIVE_ID } from './components'

export const LCI_LIVE_TMS_DATA = {
  program_programName: '',
  program_programId: '',
  program_programNameMove: '',
  program_programIdMove: '',
  program_broadcastingChannel: 'lci',
  program_attributedEditor: 'tf1info',
  content_video_videoName: '',
  content_video_videoEmNumber: '',
  content_video_videoRefvideoId: VIDEO_LIVE_ID,
  content_video_videoFormat: 'live',
  content_video_videoType: 'live',
  content_video_duration: '',
}

// HIT Types
export const DISPLAY_PAGE = 'display-page'
export const CLICK = 'click'
export const SELF_PROMOTION_CLICK = 'self_promotion_click'
export const SELF_PROMOTION_IMPRESSION = 'self_promotion_impression'
export const CLICK_VIDEO = 'click-vignette'
