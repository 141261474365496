import type { IExternalScript } from '../contexts/ExternalScripts'
import config from '../config'
import { TAGCOMMANDER_SCRIPT_ID } from '../constants/scripts'
import { waitForCondition } from '../helpers/waitForCondition'

const tagcommander: IExternalScript = {
  id: TAGCOMMANDER_SCRIPT_ID,
  src: config.tagcommander.url,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    waitForCondition({
      condition: (): boolean => !!window?.tC?.container,
      callback: (isTagCommanderReady: boolean) => setLoaded(isTagCommanderReady),
    })
  },
  consent: [],
  isBeforeInteraction: true,
}

export default tagcommander
