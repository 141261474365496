import type { IExternalScript } from '../contexts/ExternalScripts'
import { POOOL_ENGAGE_SCRIPT_ID } from '../constants/scripts'
import { PERSONALISATION, STATISTIQUES } from '../constants/cmp'

const pooolAudit: IExternalScript = {
  id: POOOL_ENGAGE_SCRIPT_ID,
  src: 'https://assets.poool.fr/engage.min.js',
  onLoad: (setLoaded) => setLoaded(true),
  async: true,
  consent: [STATISTIQUES, PERSONALISATION],
  waitFirstConsent: true,
}

export default pooolAudit
