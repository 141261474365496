import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { PageLayout } from '../components/layouts/PageLayout'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { isStatic?: boolean }
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  if (Component.isStatic) {
    return <Component {...pageProps} />
  }

  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />, pageProps)
  }

  return (
    <PageLayout pageProps={pageProps}>
      <Component {...pageProps} />
    </PageLayout>
  )
}
