import type { IExternalScript } from '../contexts/ExternalScripts'
import { BATCH_SCRIPT_ID } from '../constants/scripts'
import { PERSONALISATION } from '../constants/cmp'

const batch: IExternalScript = {
  id: BATCH_SCRIPT_ID,
  src: 'https://via.batch.com/v3/bootstrap.min.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    window.batchSDK =
      window.batchSDK ||
      function (...args) {
        window.batchSDK.q = window.batchSDK.q || []
        window.batchSDK.q.push(args)
      }

    window.batchSDK(() => setLoaded(true))
  },
  consent: [PERSONALISATION],
  waitFirstConsent: true,
}

export default batch
