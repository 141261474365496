import type { IExternalScript } from '../contexts/ExternalScripts'
import { OWNPAGE_SCRIPT_ID } from '../constants/scripts'
import { PERSONALISATION, STATISTIQUES } from '../constants/cmp'

const ownpage: IExternalScript = {
  id: OWNPAGE_SCRIPT_ID,
  src: 'https://script.ownpage.fr/v1/ownpage.js',
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => {
    setLoaded(true)
  },
  consent: [PERSONALISATION, STATISTIQUES],
}

export default ownpage
