import type { IExternalScript } from '../contexts/ExternalScripts'
import { PYM_SCRIPT_ID } from '../constants/scripts'

const pym: IExternalScript = {
  id: PYM_SCRIPT_ID,
  src: 'https://graphics.afpforum.com/vendors/pym/pym.v1.min.js',
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => {
    setLoaded(true)
  },
  consent: [],
}

export default pym
