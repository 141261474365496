import type { ImageObject } from 'schema-dts'
import config from '../../config'

export interface IImageObjectData {
  url: string
  width: string | number
  height: string | number
  format?: string
}

const presets: Record<string, IImageObjectData> = {
  logo: {
    url: `${config.domain}/images/tf1info-logo-line.png`,
    width: '861',
    height: '150',
    format: 'image/png',
  },
  defaultArticle: {
    url: `${config.domain}/images/tf1info-image-fallback-dark.jpg`,
    width: '1280',
    height: '720',
    format: 'image/jpg',
  },
}

export default function imageObject(props: {
  data?: IImageObjectData
  isAssociatedMedia?: boolean
  preset?: keyof typeof presets
}): ImageObject | null {
  const { data, preset, isAssociatedMedia } = props || {}
  const imageData: IImageObjectData = data || presets?.[preset]

  if (!imageData || !imageData?.url) {
    return null
  }

  const encodingFormat =
    imageData.format || `image/${imageData.url?.split('.').slice(-1)?.[0]}` || ''

  const urlKey = isAssociatedMedia ? 'contentUrl' : 'url'

  return {
    '@type': 'ImageObject',
    [urlKey]: imageData.url,
    encodingFormat,
    width: String(imageData.width),
    height: String(imageData.height),
  }
}
