import type { ICactConsentResult, ICMPStatus } from '../types/cmp'
import { getCookie } from './cookies'
import {
  ALL_ACCEPTED_CONSENT,
  ALL_REJECTED_CONSENT,
  REJECTED_CONSENT,
  UNSET_CONSENT,
} from '../constants/cmp'
import config from '../config'

export const isFirstConsent = () => !!getCookie('TC_PRIVACY')

export const getConsent = (callback: (result: any) => void) => {
  window.caReady = window.caReady || []
  window.cact =
    window.cact ||
    function (...args) {
      window.caReady.push(args)
    }

  window.cact('consent.get', function (result: ICactConsentResult) {
    return callback(result)
  })
}

export const updateConsent = (consent: string, active: boolean) => {
  window.cact('consent.update', {
    categories: {
      [consent]: { status: active ? 'on' : 'off' },
    },
  })
}

export const updateConsents = (
  categoriesConsents: string[] = [],
  vendorsConsents: string[] = [],
  active: boolean,
) => {
  const status = active ? 'on' : 'off'
  const categories = categoriesConsents.reduce((final, consent) => {
    return {
      ...final,
      [consent]: { status, ...(consent.startsWith('tcf') ? { legIntStatus: status } : {}) },
    }
  }, {})
  const vendors = vendorsConsents.reduce((final, consent) => {
    return {
      ...final,
      [consent]: { status },
    }
  }, {})

  window.cact('consent.update', {
    sendHit: false,
    action: 'page_click',
    status: 'mixed',
    categories,
    vendors,
  })

  if (config?.tagcommander?.syndicationUrl) {
    window.tC?.script?.add(config.tagcommander.syndicationUrl)
    window.tC?.event?.consentAds(this, {})
    window.tC?.container?.reload({ events: { page: [{}, {}] } })
  }

  return
}

export function hasCorrectConsent(
  consents: ICactConsentResult['consent'],
  requiredConsents?: string[],
  requiredVendors?: string[],
): boolean {
  if (!consents) return false

  const { categories, vendors, status } = consents

  if (status === ALL_ACCEPTED_CONSENT || (!requiredConsents?.length && !requiredVendors?.length)) {
    return true
  }

  if (([ALL_REJECTED_CONSENT, UNSET_CONSENT] as ICMPStatus[]).includes(status)) {
    return false
  }

  const hasCorrectCategoriesConsent =
    !requiredConsents?.some((v) => {
      const consentStatus = categories?.[v]?.status
      return REJECTED_CONSENT === consentStatus || consentStatus === UNSET_CONSENT
    }) || !requiredConsents?.length

  const hasCorrectVendorsConsent =
    !requiredVendors?.some((v) => {
      const consentStatus = vendors?.[v]?.status
      return REJECTED_CONSENT === consentStatus || consentStatus === UNSET_CONSENT
    }) || !requiredVendors?.length

  return hasCorrectCategoriesConsent && hasCorrectVendorsConsent
}
