import type { IExternalScript } from '../contexts/ExternalScripts'
import { NONLI_SCRIPT_ID } from '../constants/scripts'

const nonli: IExternalScript = {
  id: NONLI_SCRIPT_ID,
  src: 'https://l.tf1info.fr/sdk.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    setLoaded(true)
  },
  consent: [],
}

export default nonli
