export const PAGE_TYPE_CONTENT = 'content'
export const PAGE_TYPE_PAGE = 'page'
export const PAGE_TYPE_TAG = 'tag'
export const PAGE_TYPE_AUTHOR = 'author'
export const PAGE_TYPE_ACCOUNT = 'account'

// CONTENT SUBTYPE
export const PAGE_SUBTYPE_ARTICLE = 'article'
export const PAGE_SUBTYPE_VIDEO = 'video'

// PAGE SUBTYPE
export const PAGE_SUBTYPE_CATEGORY = 'category'
export const PAGE_SUBTYPE_OTHER = 'other'
export const PAGE_SUBTYPE_HOME = 'home'
export const PAGE_SUBTYPE_ELECTION = 'election'

// TAG TYPE
export const PAGE_SUBTYPE_KEYWORD = 'tag'
export const PAGE_SUBTYPE_TAGINVISIBLE = 'tagNotVisible'
export const PAGE_SUBTYPE_EDITO = 'edito'
export const PAGE_SUBTYPE_PROGRAM = 'program'
export const PAGE_SUBTYPE_OFFER = 'offer'
export const PAGE_SUBTYPE_PERSONALITY = 'personality'

export const BLUE_THEME_URLS = ['/emissions/', '/emissions/lci/']
