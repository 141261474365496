import slugify from 'slugify'
import { fetchCategories } from '../api/client/ssr'

export const getCategoriesLabelsFromIds = async (ids: string) => {
  const categories = await fetchCategories()
  return ids
    .split(';')
    .map((id) => categories?.find?.((category) => category.id === id)?.backofficeName)
    .filter(Boolean)
    .map((categoryName) => slugify(categoryName, { lower: true }))
    .join(';')
}
