import type { IExternalScript } from '../contexts/ExternalScripts'
import { BEOP_SCRIPT_ID } from '../constants/scripts'
import config from '../config'
import { getAccountInfo } from '../helpers/gigya'
import { GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BEOP } from '../constants/gigya'

const beop: IExternalScript = {
  id: BEOP_SCRIPT_ID,
  src: 'https://widget.beop.io/sdk.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    window.beOpAsyncInit = function () {
      window.BeOpSDK.init({
        account: config.beop.accountId,
      })
      // init user state
      window.BeOpSDK.__logUser(false)
      // Listener called by "connexion" button on beop embed
      window.BeOpSDK.__onLoginRequest(async function () {
        const user = await getAccountInfo({
          include: 'profile,data,preferences',
        })
        if (user?.errorCode !== 0) {
          window
            .open(
              `/compte/accueil?redirect=${window.location.pathname}&serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BEOP}`,
              '_blank',
            )
            .focus()
        }
        return true
      })
      window.BeOpSDK.watch()
      setLoaded(true)
    }
  },
  consent: [],
  waitFirstConsent: true,
}

export default beop
