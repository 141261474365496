export const STATISTIQUES = '1' as const
export const PERSONALISATION = '2' as const
export const RESEAUX_SOCIAUX = '3' as const
export const PUBLICITE_CIBLE = '4' as const
export const TECHNIQUE = '5' as const

export const ACCEPTED_CONSENT = 'on' as const
export const REJECTED_CONSENT = 'off' as const
export const UNSET_CONSENT = 'unset' as const

export const ALL_ACCEPTED_CONSENT = 'all-on' as const
export const ALL_REJECTED_CONSENT = 'all-off' as const
export const MIXED_CONSENT = 'mixed' as const

export const TCF_CONSENT = [
  'tcf2_1',
  'tcf2_2',
  'tcf2_3',
  'tcf2_4',
  'tcf2_5',
  'tcf2_6',
  'tcf2_7',
  'tcf2_8',
  'tcf2_9',
  'tcf2_10',
  'tcf2_sf_1',
  'tcf2_sf_2',
]

export const YOUTUBE_REQUIRED_CONSENT_VENDORS = ['tcf2_755']

export const POOOL_PUB_REQUIRED_CONSENT_CATEGORIES = [
  '4',
  'tcf2_1',
  'tcf2_2',
  'tcf2_3',
  'tcf2_4',
  'tcf2_5',
  'tcf2_6',
  'tcf2_7',
  'tcf2_8',
  'tcf2_9',
  'tcf2_10',
  'tcf2_11',
  'tcf2_12',
  'tcf2_sf_1',
  'tcf2_sf_2',
]
