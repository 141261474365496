import type { Context, ReactElement, ReactNode } from 'react'
import { createContext, useEffect, useState } from 'react'
import Script from 'next/script'
import {
  STATISTIQUES,
  PERSONALISATION,
  RESEAUX_SOCIAUX,
  PUBLICITE_CIBLE,
  TECHNIQUE,
} from '../constants/cmp'
import { isServer } from '../helpers/client'

export type IScriptConsent = (
  | typeof STATISTIQUES
  | typeof PERSONALISATION
  | typeof RESEAUX_SOCIAUX
  | typeof PUBLICITE_CIBLE
  | typeof TECHNIQUE
)[]

interface IScriptStateManagement {
  onMount?: (setLoaded: (isLoaded: boolean) => void, setError: (error: boolean) => void) => void
  onLoad?: (setLoaded: (isLoaded: boolean) => void, setError: (error: boolean) => void) => void
  onError?: (setLoaded: (isLoaded: boolean) => void, setError: (error: boolean) => void) => void
  consent: string[]
  waitFirstConsent?: boolean
  attributes?: { [key: string]: string }
}

export interface IExternalScript extends IScriptStateManagement {
  id: string
  src: string
  async?: boolean
  defer?: boolean
  isBeforeInteraction?: boolean
}

export interface IScriptState {
  active: boolean
  loaded: boolean
  error: boolean
  consent: string[]
  waitFirstConsent?: boolean
}

export interface IExternalScripts {
  scripts: Array<IExternalScript>
  children: ReactElement | ReactNode
}

export interface IExternalScriptContext {
  scripts: {
    [key: string]: IScriptState
  }
  removeScript: (id: string) => void
  activeScript: (id: string) => void
}

export const ScriptContext: Context<IExternalScriptContext> = createContext({
  scripts: {},
  removeScript: (id) => id,
  activeScript: (id) => id,
})

// Vendor script management
// To add a script when needed, add script info in app.tsx
// use activeScript to load your script in your component with useContext
export function ExternalScripts({ scripts, children }: IExternalScripts): JSX.Element {
  const [scriptsState, setScriptsState] = useState<{
    [key: string]: IScriptState
  }>(
    // Init active and loaded state for each script
    scripts.reduce(
      (neededScripts, { id, consent, waitFirstConsent, attributes, isBeforeInteraction }) => ({
        ...neededScripts,
        [id]: {
          active: !!isBeforeInteraction,
          loaded: false,
          error: false,
          consent,
          waitFirstConsent,
          attributes,
        },
      }),
      {},
    ),
  )

  useEffect(() => {
    if (!isServer()) {
      scripts.forEach((script) => {
        if (script.isBeforeInteraction) {
          script?.onMount(setScriptLoaded(script.id), setScriptError(script.id))
        }
      })
    }
  }, [])

  // Create a function to set the loaded property from a script ID
  function setScriptLoaded(id: string) {
    return function setScriptLoadedFn(loaded: boolean) {
      setScriptsState((prevState) => ({ ...prevState, [id]: { ...prevState[id], loaded } }))
    }
  }

  // Create a function to set the error property from a script ID
  function setScriptError(id: string) {
    return function setScriptErrorFn(error: boolean) {
      setScriptsState((prevState) => ({ ...prevState, [id]: { ...prevState[id], error } }))
    }
  }

  // Add the script on the head
  function activeScript(id: string) {
    const activedScript = scripts.find((script) => script.id === id)
    // Run the onMount function to set if the script is loaded and usable
    activedScript?.onMount && activedScript.onMount(setScriptLoaded(id), setScriptError(id))
    setScriptsState((prevState) => ({ ...prevState, [id]: { ...prevState[id], active: true } }))
  }

  // Remove the script on the head
  function removeScript(id: string) {
    setScriptsState((prevState) => ({ ...prevState, [id]: { ...prevState[id], active: false } }))
  }

  return (
    <>
      {scripts
        ?.filter(({ isBeforeInteraction }) => !isBeforeInteraction)
        ?.map?.(({ src, id, onLoad, onError, attributes }) => {
          const isActive = scriptsState?.[id]?.active
          if (!isActive) return null
          const onLoadScript = () => onLoad && onLoad(setScriptLoaded(id), setScriptError(id))
          const onErrorScript = () =>
            onError ? onError(setScriptLoaded(id), setScriptError(id)) : setScriptError(id)(true)

          return (
            <Script
              key={id}
              src={src}
              onLoad={onLoadScript}
              onError={onErrorScript}
              strategy="lazyOnload"
              {...(attributes || {})}
            ></Script>
          )
        })}
      <ScriptContext.Provider value={{ scripts: scriptsState, activeScript, removeScript }}>
        {children}
      </ScriptContext.Provider>
    </>
  )
}
