import type { IExternalScript } from '../contexts/ExternalScripts'
import { INSTAGRAM_SCRIPT_ID } from '../constants/scripts'
import { RESEAUX_SOCIAUX } from '../constants/cmp'

const instagram: IExternalScript = {
  id: INSTAGRAM_SCRIPT_ID,
  src: '//www.instagram.com/embed.js',
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => setLoaded(true),
  consent: [RESEAUX_SOCIAUX],
}

export default instagram
