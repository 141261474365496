import { theme } from './theme'

export const mixins = {
  fonts: {
    item: `
      font-family: var(--font-overpass);
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 146%;
    `,
    base: {
      large: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 144%;
      `,
      medium: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 146%;
      `,
      small: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 145%;
      `,
      xsmall: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 100%;
      `,
    },
    article: {
      interTitle: `
        font-family: var(--font-overpass);
        font-size: 30px;
        font-weight: 300;
        line-height: 120%;
      `,
      h2: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 109%;
      `,
      h3: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 133%;
      `,
      h5: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 100%;
      `,
      h6: `
        font-family: var(--font-overpass);
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 146%;
      `,
      quote: `
        font-family: var(--font-overpass);
        font-style: italic;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
      `,
    },
  },
  mediaQuery: {
    mobile: `(min-width: ${theme.breakpoints.sm}px)`,
    onlyMobile: `(min-width: ${theme.breakpoints.sm}px) and (max-width: ${
      theme.breakpoints.md - 1
    }px)`,
    onlyPortrait: `(orientation:portrait)`,
    tablet: `(min-width: ${theme.breakpoints.md}px)`,
    onlyTablet: `(min-width: ${theme.breakpoints.md}px) and (max-width: ${
      theme.breakpoints.lg - 1
    }px)`,
    tabletPaysage: `(min-width: ${theme.breakpoints.lg}px)`,
    onlyTabletPaysage: `(min-width: ${theme.breakpoints.lg}px) and (max-width: ${
      theme.breakpoints.xl - 1
    }px)`,
    desktop: `(min-width: ${theme.breakpoints.xl}px)`,
    maxWidth: `(min-width: ${theme.layout.maxWidth}px)`,
  },
  header: {
    maxWidthDesktop: `
        margin: auto;
        padding: 0  ${theme.block.marginLR}px;
        max-width: ${theme.layout.maxWidth}px;
      `,
  },
} as const
