import type { NewsArticle, SpeakableSpecification } from 'schema-dts'
import type { IPage } from '../../types/page'
import type { IReadMoreArticle } from '../../components/molecules/ReadMoreArticle'

import { formatKeywords } from '../seoFormat'
import { getBlockFromMainBody } from '../blocks'
import imageObject from './imageObject'
import newsMediaOrganizationObject from './newsMediaOrganization'
import postalAddressObject from './postalAddress'
import creativeWork from './creativeWork'
import { journalistPersonObject } from './person'
import associatedMediaObject from './associatedMedia'
import config from '../../config'
import { BlockTypes } from '@etf1-interne/tf1info_types_news'

type ISpeakable = { speakable: SpeakableSpecification }

export default function newsArticle(page: IPage['page']): NewsArticle {
  if (!page) return null
  const keywords: string[] = formatKeywords(page.seo.newsMetaKeywords)
  const associatedMedia = associatedMediaObject(page, keywords)

  const readMoreBlock = getBlockFromMainBody(page, 'article-read-more')?.data as IReadMoreArticle
  const readMoreList = readMoreBlock?.elementList || []

  function getSpeakable(): ISpeakable {
    const articleChapoData = getBlockFromMainBody(page, 'article-chapo')
      ?.data as BlockTypes['article-chapo']['data']
    const cssSelectorChapo =
      articleChapoData?.elementList?.map?.((_, index) => `.ArticleChapo__Point__${index}`) || []

    return {
      speakable: {
        '@type': 'SpeakableSpecification',
        cssSelector: ['.ArticleHeaderTitle__Wrapper h1', ...cssSelectorChapo],
      },
    }
  }

  function getCitation() {
    const tagsCitation =
      page.tags?.reduce((acc, { isVisible, link, name }) => {
        if (!isVisible || !link) return acc
        return [...acc, creativeWork({ url: `${config.domain}${link}`, text: name })]
      }, []) ?? []

    const readMoreCitation =
      readMoreList
        ?.filter((item) => item.link)
        .map(({ link, description }) =>
          creativeWork({ url: `${config.domain}${link}`, text: description }),
        ) ?? []

    const citation = [...tagsCitation, ...readMoreCitation]

    return citation.length ? { citation } : {}
  }

  return {
    '@type': 'NewsArticle',
    associatedMedia,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${config.domain}${page.url}`,
    },
    datePublished: page.createdAt || '',
    dateModified: page.updatedAt || '',
    isAccessibleForFree: true,
    headline: page.h1 || '',
    alternativeHeadline: page.editorTitle || '',
    image: imageObject({ data: page.image, preset: 'defaultArticle' }),
    articleSection: page?.category?.name || '',
    inLanguage: 'fr',
    description: page.description || '',
    ...(page.articleBody ? { articleBody: page.articleBody } : {}),
    keywords,
    contentLocation: {
      '@type': 'Place',
      address: postalAddressObject(page.location, null),
    },
    ...getCitation(),
    ...getSpeakable(),
    author: journalistPersonObject(page?.authors),
    publisher: newsMediaOrganizationObject(),
  }
}
