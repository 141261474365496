import type { IExternalScript } from '../contexts/ExternalScripts'
import { PUBSTACK_SCRIPT_ID } from '../constants/scripts'

const pubstack: IExternalScript = {
  id: PUBSTACK_SCRIPT_ID,
  src: 'https://boot.pbstck.com/v1/tag/f6fd266f-0028-4a8b-8e48-a29866afe947',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => setLoaded(true),
  consent: [],
}

export default pubstack
