// Generic Events
export const CLICK = 'click' as const

// Player Events
export const AUTOPLAY_FAILED = 'player:autoplay:failed' as const
export const READY = 'ready' as const
export const MEDIA_INFO = 'mediainfo:update' as const
export const COMINGNEXT_NEXT_CLICK = 'comingNext:nextVideo' as const
export const START = 'timeupdate' as const
export const PLAY = 'play' as const
export const PLAYING = 'playing' as const
export const MUTE = 'mute' as const
export const UNMUTE = 'unmute' as const
export const PLAY_ON_BUTTON_CLICK = 'play:button:click' as const
export const PLAY_ON_MEDIA_CLICK = 'play:media:click' as const
export const PAUSE = 'pause' as const
export const END = 'ended' as const
export const ROLL_END = 'end-roll' as const
export const QUARTILE = 'quartile' as const // artificial event pulled from timeupdate
export const ERROR = 'error' as const
export const SEEK = 'seek' as const
export const SEEKED = 'seeked' as const
export const SEEK_FORWARD_SHORTCUT = 'seekForward:button:shortcut' as const
export const SEEK_BACK_SHORTCUT = 'seekBack:button:shortcut' as const
export const CONTROLS_SHOW = 'controls:show' as const
export const CONTROLS_HIDE = 'controls:hide' as const
export const AD_COMPANION = 'ads:ad:companion' as const
export const AD_END = 'ads:ad:end' as const
export const AD_ERROR = 'ads:ad:error' as const
export const AD_SKIP = 'ads:ad:skip' as const
export const AD_RESUME = 'ads:ad:resume' as const
export const AD_PAUSE = 'ads:ad:pause' as const
export const AD_START = 'ads:ad:start' as const
export const AD_UPDATE = 'ads:ad:update' as const
export const AD_CLICKED = 'ads:ad:clicked' as const
export const AD_PAUSE_DISPLAY = 'ads:ad:adPauseDisplay' as const
export const AD_PAUSE_CLICK = 'ads:ad:adPauseClick' as const
export const SLOT_START = 'ads:slot:start' as const
export const SLOT_END = 'ads:slot:end' as const
export const BUFFERING_START = 'buffering:start' as const
export const BUFFERING_END = 'buffering:end' as const
export const PLAYER_READY = 'ready' as const
export const FULLSCREEN_ENTER = 'fullscreenEnter' as const
export const FULLSCREEN_EXIT = 'fullscreenExit' as const
export const JWT_EXPIRED = 'jwt:expired' as const

export const PAUSE_ON_BUTTON_CLICK = 'pause:button:click' as const
export const SEEK_FORWARD_ON_BUTTON_CLICK = 'seekForward:button:click' as const
export const SEEK_BACK_ON_BUTTON_CLICK = 'seekBack:button:click' as const
export const UNMUTE_ON_BUTTON_CLICK = 'unMute:button:click' as const
export const MUTE_ON_BUTTON_CLICK = 'mute:button:click' as const
export const FULLSCREEN_ENTER_ON_BUTTON_CLICK = 'fullscreenEnter:button:click' as const
export const FULLSCREEN_EXIT_ON_BUTTON_CLICK = 'fullscreenExit:button:click' as const
export const FULLSCREEN_ENTER_ON_MEDIA_DOUBLECLICK_CLICK =
  'fullscreenEnter:media:doubleClick' as const
export const FULLSCREEN_EXIT_ON_MEDIA_DOUBLECLICK_CLICK =
  'fullscreenExit:media:doubleClick' as const
export const FULLSCREEN_ENTER_ON_SHORTCUT_CLICK = 'fullscreenEnter:keyboard:shortcut' as const
export const FULLSCREEN_EXIT_ON_SHORTCUT_CLICK = 'fullscreenExit:keyboard:shortcut' as const

// TMS Player event
export const CTA_PLAYER = 'cta-player' as const
export const TMS_ACTION_END = 'stop' as const // Does that make any sense ? no.
export const TMS_ACTION_START = 'play' as const // Does that make any sense ? no.
export const TMS_BUTTON_EXIT_FULLSCREEN = 'player-standard' as const
export const TMS_BUTTON_FULLSCREEN = 'player-fullscreen' as const
export const TMS_BUTTON_MUTE = 'player-mute' as const
export const TMS_BUTTON_PAUSE = 'player-pause' as const
export const TMS_BUTTON_PLAY = 'player-play' as const
export const TMS_BUTTON_UNMUTE = 'player-unmute' as const
export const TMS_COMINGNEXT_NEXT_CLICK = 'comingNext:nextVideo:click' as const
export const TMS_END = 'end' as const
export const TMS_PAUSE = 'pause' as const
export const TMS_PLAY = 'play' as const
export const TMS_PLAYALONG_AUTO = 'auto' as const
export const TMS_PLAYALONG_FALSE = 'false' as const
export const TMS_PLAYALONG_MANUAL = 'manuel' as const
export const TMS_QUARTILE = 'quartile-passed' as const
export const TMS_ROLL_PLAY = 'start-roll' as const
export const TMS_SEEK = 'seek' as const
export const TMS_SEEK_BACKWARD = 'player-retour-rapide' as const
export const TMS_SEEK_FORWARD = 'player-avance-rapide' as const
export const TMS_SEEKED = 'seeked' as const
export const TMS_SPOT_END = 'end-spot' as const
export const TMS_SPOT_PAUSE = 'pause-spot' as const
export const TMS_SPOT_PLAY = 'play-spot' as const
export const TMS_SPOT_RESUME = 'resume-spot' as const
export const TMS_START = 'start' as const

// Map event name from tf1 player with event name in tagCommander datalayer
export const EVENTS_MAPPING = {
  [START]: TMS_START,
  [COMINGNEXT_NEXT_CLICK]: TMS_COMINGNEXT_NEXT_CLICK,
  [PLAY]: TMS_PLAY,
  [PAUSE]: TMS_PAUSE,
  [END]: TMS_END,
  [QUARTILE]: TMS_QUARTILE,
  [SEEK]: TMS_SEEK,
  [SEEKED]: TMS_SEEKED,
  [CLICK]: CTA_PLAYER,
} as const

// Map event name from tf1 player with action name in tagCommander datalayer (content_video_action)
export const ACTIONS_MAPPING = {
  [START]: TMS_ACTION_START,
  [END]: TMS_ACTION_END,
  [SEEK]: TMS_SEEK,
  [SEEKED]: TMS_SEEKED,
  [PAUSE]: TMS_PAUSE,
} as const

// Map event name from tf1 player with action name in tagCommander datalayer (screen_clickableElementName)
export const CTA_PLAYER_MAPPING = {
  [PLAY_ON_BUTTON_CLICK]: TMS_BUTTON_PLAY,
  [PAUSE_ON_BUTTON_CLICK]: TMS_BUTTON_PAUSE,
  [UNMUTE_ON_BUTTON_CLICK]: TMS_BUTTON_MUTE,
  [MUTE_ON_BUTTON_CLICK]: TMS_BUTTON_UNMUTE,
  [FULLSCREEN_ENTER_ON_BUTTON_CLICK]: TMS_BUTTON_FULLSCREEN,
  [FULLSCREEN_EXIT_ON_BUTTON_CLICK]: TMS_BUTTON_EXIT_FULLSCREEN,
  [FULLSCREEN_ENTER_ON_MEDIA_DOUBLECLICK_CLICK]: TMS_BUTTON_FULLSCREEN,
  [FULLSCREEN_EXIT_ON_MEDIA_DOUBLECLICK_CLICK]: TMS_BUTTON_EXIT_FULLSCREEN,
  [FULLSCREEN_ENTER_ON_SHORTCUT_CLICK]: TMS_BUTTON_FULLSCREEN,
  [FULLSCREEN_EXIT_ON_SHORTCUT_CLICK]: TMS_BUTTON_EXIT_FULLSCREEN,
} as const

export const QUARTILES = [25, 50, 75, 90]

export type EVENTS =
  | typeof AUTOPLAY_FAILED
  | typeof READY
  | typeof MEDIA_INFO
  | typeof COMINGNEXT_NEXT_CLICK
  | typeof START
  | typeof PLAY
  | typeof PLAY_ON_BUTTON_CLICK
  | typeof PLAY_ON_MEDIA_CLICK
  | typeof PAUSE
  | typeof END
  | typeof QUARTILE
  | typeof ERROR
  | typeof AD_COMPANION
  | typeof AD_END
  | typeof AD_ERROR
  | typeof AD_SKIP
  | typeof AD_RESUME
  | typeof AD_PAUSE
  | typeof BUFFERING_START
  | typeof BUFFERING_END
  | typeof SEEK
  | typeof SEEKED
  | typeof SEEK_FORWARD_ON_BUTTON_CLICK
  | typeof SEEK_FORWARD_SHORTCUT
  | typeof SEEK_BACK_ON_BUTTON_CLICK
  | typeof SEEK_BACK_SHORTCUT
  | typeof FULLSCREEN_EXIT_ON_BUTTON_CLICK
  | typeof FULLSCREEN_ENTER_ON_MEDIA_DOUBLECLICK_CLICK
  | typeof FULLSCREEN_EXIT_ON_MEDIA_DOUBLECLICK_CLICK
  | typeof FULLSCREEN_ENTER_ON_SHORTCUT_CLICK
  | typeof FULLSCREEN_EXIT_ON_SHORTCUT_CLICK
