import { ReactElement, ReactNode, useRef } from 'react'
import type { IVideosData, IPlayerData, ITmsData, ITms } from '../../../types/tms'
import type { User } from '../../../types/user'
import { useEffect, useState, useCallback } from 'react'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { TAGCOMMANDER_SCRIPT_ID } from '../../../constants/scripts'
import { CLICK, DISPLAY_PAGE } from '../../../constants/tms'
import { useScript } from '../../../hook/useScript'
import { useUser } from '../../../hook/useUser'
import { generateTMSClientData } from '../../../helpers/tms'
import { getCookie } from '../../../helpers/cookies'

export interface ITagCommander {
  tms: ITms
  children: ReactElement | ReactNode
  loadOnMount?: boolean
}

export interface IHitOptions {
  changedPath?: string
  isClickEvent?: boolean
  delay?: number
}

const MAX_EVENTS_IN_QUEUE_BEFORE_EMPTYING = 2

export function TagCommander({ tms, children, loadOnMount = true }: ITagCommander): JSX.Element {
  // Active the script
  const { isScriptLoaded } = useScript({
    id: TAGCOMMANDER_SCRIPT_ID,
    loadOnMount,
  })
  const { userProfile, isLoggedIn, loading: isUserDataLoading } = useUser()
  const [clientData, setClientData] = useState<ITmsData>({})
  const [videosData, setVideosData] = useState<IVideosData>(tms?.videos || {})
  const [pageData, setPageData] = useState<ITmsData>(tms?.page || {})
  const [playerData, setPlayerData] = useState<IPlayerData>(tms?.player || {})
  const hasHitDisplayPage = useRef(false)
  const eventQueue = useRef([])

  const updateTcVars = useCallback(
    function updateTcVarsCallback(data = {}) {
      const content_video_playAlong = getCookie('playAlong')
      window.tc_vars = {
        ...pageData,
        ...clientData,
        ...data,
        //GET cookie with value TMS_PLAYALONG_FALSE or TMS_PLAYALONG_AUTO for video Page
        ...(content_video_playAlong ? { content_video_playAlong } : {}),
      }
    },
    [pageData, clientData],
  )

  const sendHit = useCallback(
    function sendHit(data: ITmsData, options: IHitOptions) {
      updateTcVars({
        ...(options?.isClickEvent && { id: CLICK }),
        ...data,
        screen_url: options?.changedPath || clientData.screen_url,
      })

      // Print tagCommander data before sending it to facilitate testing
      console.log(
        `%cSending tc_vars to tagCo :: ${window.tc_vars?.id}`,
        'font-weight: bold; color: darkGreen;',
        JSON.parse(JSON.stringify(window.tc_vars)),
      )

      try {
        if (options?.isClickEvent) {
          window.tC?.event?.[CLICK]?.(this, window.tc_vars)
        } else {
          window.tC.container.reload({ events: { page: [{}, {}] } })
        }
      } catch (error) {
        console.error('TMS reload error : ', error)
      }
    },
    [updateTcVars],
  )

  const hitWithDelay = useCallback(
    (data: ITmsData, options: IHitOptions = {}) => {
      const { delay } = options

      if (delay) {
        setTimeout(() => sendHit(data, options), delay)
      } else {
        sendHit(data, options)
      }
    },
    [sendHit],
  )

  const addEventToQueue = useCallback(
    (data: ITmsData, options: IHitOptions) => {
      eventQueue.current = [...eventQueue.current, { data, options }]
    },
    [eventQueue],
  )

  const emptyEventQueue = useCallback(() => {
    eventQueue.current.forEach(({ data, options }) => {
      hitWithDelay(data, options)
    })
    eventQueue.current = []
  }, [hitWithDelay, eventQueue])

  // Handle tms hit with queue to wait for display-page before sending any other hit
  const handleHitWithQueue = useCallback(
    (data: ITmsData, options?: IHitOptions) => {
      const eventData = {
        ...pageData,
        ...clientData,
        ...data,
      }

      if (hasHitDisplayPage.current) {
        if (eventQueue.current?.length) {
          emptyEventQueue()
        }
        hitWithDelay(data, options)
        return
      }

      if (eventData?.id === DISPLAY_PAGE && !options?.isClickEvent) {
        hasHitDisplayPage.current = true
        hitWithDelay(data, options)
        emptyEventQueue()
        return
      }

      addEventToQueue(data, options)
      // If queue reach the max events, disable the queue for the next hit
      hasHitDisplayPage.current =
        eventQueue.current.length > MAX_EVENTS_IN_QUEUE_BEFORE_EMPTYING || hasHitDisplayPage.current
    },
    [clientData, pageData, eventQueue, addEventToQueue, emptyEventQueue, hitWithDelay],
  )

  async function buildClientData(user: User, userConnected: boolean) {
    const data = await generateTMSClientData(user, userConnected)
    setClientData(data)
  }

  useEffect(() => {
    if (!isUserDataLoading && isScriptLoaded) {
      setPageData((prevState) => ({ ...(prevState || {}), ...(tms?.page || {}) }))
      setVideosData((prevState) => ({ ...(prevState || {}), ...(tms?.videos || {}) }))
      setPlayerData(tms?.player || {})
      buildClientData(userProfile, isLoggedIn)
    }
  }, [isUserDataLoading, userProfile, isScriptLoaded, tms, isLoggedIn])

  return (
    <TagCommanderContext.Provider
      value={{
        hit: handleHitWithQueue,
        isTagCommanderReady: isScriptLoaded && !!clientData.id,
        clientData,
        pageData,
        playerData,
        videosData,
        setClientData,
        setPageData,
        setPlayerData,
        setVideosData,
        updateTcVars,
      }}
    >
      {children}
    </TagCommanderContext.Provider>
  )
}
