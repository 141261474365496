import type { IExternalScript } from '../contexts/ExternalScripts'
import { FACEBOOK_SCRIPT_ID } from '../constants/scripts'
import { RESEAUX_SOCIAUX } from '../constants/cmp'
import config from '../config'

const facebook: IExternalScript = {
  id: FACEBOOK_SCRIPT_ID,
  src: `//connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v19.0&appId=${config.facebook.appId}`,
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => setLoaded(true),
  consent: [RESEAUX_SOCIAUX],
}

export default facebook
