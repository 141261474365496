import type { IExternalScript } from '../contexts/ExternalScripts'
import { GOOGLE_AD_MANAGER_SCRIPT_ID } from '../constants/scripts'
import { PUBLICITE_CIBLE } from '../constants/cmp'

const gam: IExternalScript = {
  id: GOOGLE_AD_MANAGER_SCRIPT_ID,
  src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - GPT is initialized
    window.googletag = window.googletag || { cmd: [] }

    window.googletag.cmd.push(() => {
      setLoaded(true)
    })
  },
  consent: [PUBLICITE_CIBLE],
}

export default gam
