import Head from 'next/head'
import { SMO } from '../../../types/page'
import config from '../../../config'

export interface ISEOTwitter {
  title: string
  description: string
  image?: {
    url?: string
  }
  smo: SMO
}

export function SEOTwitter({
  title: pageTitle,
  description: pageDescription,
  image: pageImage,
  smo,
}: ISEOTwitter): JSX.Element {
  const { title, description, iosUrl, iphoneId, ipadId, androidId, androidUrl, domain, site } =
    smo?.twitter || {}
  const twitterTitle: string = title || pageTitle || ''
  const twitterDescription: string = description || smo?.description || pageDescription || ''
  const twitterImage: string =
    smo?.image || pageImage?.url || `${config.domain}/images/tf1info-share-default.jpg`

  return (
    <Head>
      {site && (
        <>
          <meta name="twitter:site" content={site} />
          <meta name="twitter:creator" content={site} />
          <meta name="twitter:app:name:iphone" content={site} />
          <meta name="twitter:app:name:ipad" content={site} />
          <meta name="twitter:app:name:googleplay" content={site} />
        </>
      )}
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:src" content={twitterImage} />
      <meta name="twitter:domain" content={domain} />
      <meta name="twitter:app:url:iphone" content={iosUrl} />
      <meta name="twitter:app:url:ipad" content={iosUrl} />
      <meta name="twitter:app:url:googleplay" content={androidUrl} />
      <meta name="twitter:app:id:iphone" content={iphoneId} />
      <meta name="twitter:app:id:ipad" content={ipadId} />
      <meta name="twitter:app:id:googleplay" content={androidId} />
    </Head>
  )
}
