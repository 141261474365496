export function pickBy(object, filter = (obj) => obj): { [key: string]: unknown } {
  const isNilOrNaN = (value) => value == null || Number.isNaN(value)
  const obj = Object.fromEntries(Object.entries(object).filter(([_, value]) => !isNilOrNaN(value)))

  return filter(obj)
}

export function pickByString(object, filter = (obj) => obj): { [key: string]: string } {
  const isString = (value) => typeof value == 'string'
  const obj = Object.fromEntries(Object.entries(object).filter(([_, value]) => isString(value)))

  return filter(obj)
}
