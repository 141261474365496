import type { IExternalScript } from '../contexts/ExternalScripts'
import { TWITTER_SCRIPT_ID } from '../constants/scripts'
import { RESEAUX_SOCIAUX } from '../constants/cmp'

const twitter: IExternalScript = {
  id: TWITTER_SCRIPT_ID,
  src: 'https://platform.twitter.com/widgets.js',
  async: true,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    if (window?.twttr?.widgets) {
      setLoaded(true)
    } else {
      window.twttr = window.twttr || { _e: [() => setLoaded(true)] }
    }
  },
  consent: [RESEAUX_SOCIAUX],
}

export default twitter
