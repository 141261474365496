import type { Context, Dispatch, SetStateAction } from 'react'
import type { IVideosData, ITmsData, IPlayerData } from '../types/tms'
import type { IHitOptions } from '../components/atoms/TagCommander'
import { createContext } from 'react'

export interface ITagCommanderContext {
  isTagCommanderReady: boolean
  clientData: ITmsData
  pageData: ITmsData
  playerData: IPlayerData
  videosData: IVideosData
  hit: (data: ITmsData, options?: IHitOptions) => void
  setClientData: Dispatch<SetStateAction<ITmsData>>
  setPageData: Dispatch<SetStateAction<ITmsData>>
  setPlayerData: Dispatch<SetStateAction<IPlayerData>>
  setVideosData: Dispatch<SetStateAction<IVideosData>>
  updateTcVars: (data?: ITmsData) => void
}

export const TagCommanderContext: Context<ITagCommanderContext> = createContext({
  isTagCommanderReady: false,
  clientData: {},
  pageData: {},
  playerData: {},
  videosData: {},
  hit: () => null,
  setClientData: () => null,
  setPageData: () => null,
  setPlayerData: () => null,
  setVideosData: () => null,
  updateTcVars: () => null,
})
