import type { BlogPosting } from 'schema-dts'
import type { IPageData } from '../../types/page'
import type { IMultiLiveItem } from '../../components/molecules/MultiLiveItem'
import config from '../../config'
import { formatDate, seoMultiliveArticleBody, seoMultiliveHeadline } from '../seoFormat'
import newsMediaOrganizationObject from './newsMediaOrganization'
import { journalistPersonObject } from './person'

export default function blogPostingObject(
  liveItem: IMultiLiveItem,
  author: IPageData['authors'],
  url: string,
): BlogPosting {
  const { id, date, tagline } = liveItem

  const getDefaultHeadline = (): string => `Live update du ${formatDate(date)}`

  let data = {
    url: `${url}#post-${id}`,
    headline: tagline ? seoMultiliveHeadline(tagline) : getDefaultHeadline(),
    articleBody: tagline ? seoMultiliveArticleBody(tagline) : '',
  }
  if ('type' in liveItem) {
    switch (liveItem.type) {
      case 'text':
        data = {
          url: `${url}#post-${id}`,
          headline: tagline ? seoMultiliveHeadline(tagline) : getDefaultHeadline(),
          articleBody: tagline ? seoMultiliveArticleBody(tagline) : '',
        }
        break
      case 'content':
        data = {
          url: `${config.baseUrl}${liveItem.link}`,
          headline: tagline ? seoMultiliveHeadline(tagline) : getDefaultHeadline(),
          articleBody: liveItem.title || '',
        }
        break
      case 'video':
        data = {
          url: `${url}#post-${id}`,
          headline: tagline ? seoMultiliveHeadline(tagline) : getDefaultHeadline(),
          articleBody: liveItem.video?.title || '',
        }
        break
      case 'embed':
        data = {
          url: `${url}#post-${id}`,
          headline: tagline
            ? seoMultiliveHeadline(tagline)
            : `Vu sur ${liveItem.socialType} le ${formatDate(date)}`,
          articleBody: tagline ? seoMultiliveArticleBody(tagline) : `Vu sur ${liveItem.socialType}`,
        }
        break
      case 'image':
        data = {
          url: `${url}#post-${id}`,
          headline: tagline ? seoMultiliveHeadline(tagline) : getDefaultHeadline(),
          articleBody: tagline ? seoMultiliveArticleBody(tagline) : '',
        }
        break
      default:
        break
    }
  }

  return {
    '@type': 'BlogPosting',
    ...data,
    datePublished: date,
    dateModified: date,
    author: journalistPersonObject(author),
    publisher: newsMediaOrganizationObject(),
  }
}
