import type { IExternalScript } from '../contexts/ExternalScripts'
import config from '../config'
import { getConsent, hasCorrectConsent } from '../helpers/cmp'
import { POOOL_AUDIT_SCRIPT_ID } from '../constants/scripts'
import { PERSONALISATION, STATISTIQUES } from '../constants/cmp'

const pooolAudit: IExternalScript = {
  id: POOOL_AUDIT_SCRIPT_ID,
  src: 'https://assets.poool.fr/audit.min.js',
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => {
    getConsent((result) => {
      const cookiesEnabled = hasCorrectConsent(result.consent, [STATISTIQUES, PERSONALISATION])
      window.Audit.init(config.poool.appId)
        .config({ debug: config.poool.debug, cookies_enabled: cookiesEnabled })
        .sendEvent('page-view', { type: 'premium' })
      setLoaded(true)
    })
  },
  consent: [],
  waitFirstConsent: true,
}

export default pooolAudit
