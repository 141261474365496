import type { IExternalScript } from '../contexts/ExternalScripts'
import { POOOL_ACCESS_SCRIPT_ID } from '../constants/scripts'

const pooolAccess: IExternalScript = {
  id: POOOL_ACCESS_SCRIPT_ID,
  src: 'https://assets.poool.fr/access.min.js',
  async: true,
  onLoad: (setLoaded: (isLoaded: boolean) => void): void => setLoaded(true),
  consent: [],
  waitFirstConsent: true,
}

export default pooolAccess
