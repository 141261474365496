export function debounce(func: () => void, wait: number, immediate?: boolean): () => void {
  let timeout
  return function (...args) {
    const later = function () {
      timeout = null
      if (!immediate) func.apply(this, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(this, args)
  }
}
