import type { WithContext, ClaimReview } from 'schema-dts'
import type { IPage } from '../../types/page'
import config from '../../config'
import { formatKeywords } from '../seoFormat'
import personObject, { journalistPersonObject } from './person'
import newsMediaOrganizationObject from './newsMediaOrganization'

export default function claimReviewObject(page: IPage['page']): WithContext<ClaimReview> {
  const { factChecking } = page.seo

  const keywords: string[] = formatKeywords(page.seo.newsMetaKeywords)

  return {
    '@context': 'https://schema.org',
    '@type': 'ClaimReview',
    datePublished: page.createdAt,
    dateModified: page.updatedAt,
    url: `${config.domain}${page.url}`,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${config.domain}${page.url}`,
    },
    isAccessibleForFree: true,
    inLanguage: 'fr',
    description: page.description || '',
    reviewBody: page.articleBody || '',
    keywords,
    claimReviewed: factChecking.claim,
    itemReviewed: {
      '@type': 'Claim',
      datePublished: factChecking.date || '',
      author: factChecking.author ? personObject({ name: factChecking.author }) : '',
      publisher: {
        '@type': 'Organization',
        name: factChecking.editor || '',
        sameAs: factChecking.editorUrl || '',
      },
      appearance: {
        '@type': 'OpinionNewsArticle',
        url: factChecking.claimUrl || '',
        headline: factChecking.claim,
        datePublished: factChecking.date || '',
      },
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: `${factChecking.evaluation}`,
      bestRating: '5',
      worstRating: '1',
      alternateName: factChecking.evaluationName,
    },
    ...(page.authors?.length ? { author: journalistPersonObject(page.authors[0]) } : {}),
    publisher: newsMediaOrganizationObject(),
  }
}
