import type { IExternalScript } from '../contexts/ExternalScripts'
import config from '../config'
import { GIGYA_SCRIPT_ID } from '../constants/scripts'

const gigya: IExternalScript = {
  id: GIGYA_SCRIPT_ID,
  src: `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${config.gigya.apiKey}&lang=fr`,
  onMount: (setLoaded: (isLoaded: boolean) => void): void => {
    if (window?.gigya) {
      setLoaded(true)
    } else {
      window.onGigyaServiceReady = () => setLoaded(true)
    }
  },
  consent: [],
  isBeforeInteraction: true,
}

export default gigya
